.sidebar-div {
  width: 520px;

  .panel-div {
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 105px;
    margin-bottom: 105px;
  }

  .div-text {
    margin-top: 105px;
    margin-left: 104px;
  }

  .header-text {
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
  }

  .step-item {
    display: flex;
    gap: 24px;
    padding: 8px;

    &.active {
      font-weight: bold;
      color: #1890ff;
    }
  }

  .customSteps {
    margin-top: 45px;

    .ant-steps-item-finish .ant-steps-item-icon {
      background-color: #19c37d !important;
      border-color: #19c37d !important;
      color: #e4e4eb !important;
    }

    .ant-steps-item-active {
      .ant-steps-item-icon {
        border-color: #1890ff !important;
      }

      .ant-steps-icon {
        color: #1890ff !important;
      }

      .ant-steps-item-title {
        color: #1890ff !important;
      }
    }

    .ant-steps-item-icon {
      background-color: white;
      border-color: #555770;

      .ant-steps-icon {
        top: unset !important;
      }

      span {
        color: #555770 !important;
      }

      svg {
        color: #fff;
        font-size: initial;
        padding-bottom: 4px;
      }
    }

    .ant-steps-item-title {
      color: #fff !important;
    }

    .ant-steps-item-tail {
      display: none !important;
    }

    .ant-steps-item-icon {
      background-color: transparent !important;
    }

    .ant-steps-item .ant-steps-item-wait .ant-steps-item-container {
      .ant-steps-item-icon {
        background-color: white !important;
        border-color: green !important;
        color: #555770 !important;

        svg {
          color: #fff;
          padding-bottom: 4px;
        }
      }
    }
  }
}
