@font-face {
  font-family: "Gotham Book";
  src: url("./assets/fonts/Gotham Book.otf") format("opentype");
}

@font-face {
  font-family: "Gotham Bold";
  src: url("./assets/fonts/Gotham Bold.otf") format("opentype");
}

@font-face {
  font-family: "Gotham Medium";
  src: url("./assets/fonts/Gotham-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Gotham Light";
  src: url("./assets/fonts/Gotham-Light.otf") format("opentype");
}

@font-face {
  font-family: "Gotham Thin";
  src: url("./assets/fonts/Gotham-Thin.otf") format("opentype");
}

@font-face {
  font-family: "Gotham XLight";
  src: url("./assets/fonts/Gotham-XLight.otf") format("opentype");
}

:root {
  /* Font */
  // --font-bold: "Gotham bold", Tahoma, Geneva, sans-serif;
  // --font-normal: "Gotham Book", Tahoma, Geneva, sans-serif;
  // --font-thin: "Gotham thin", Tahoma, Geneva, sans-serif;
  // --font-light: "Gotham light", Tahoma, Geneva, sans-serif;
  // --font-xlight: "Gotham xlight", Tahoma, Geneva, sans-serif;
  --font-normal: "Inter", sans-serif;

  /* Variables */
  --itrazo-red-color: #e33622;
  --primary-color: #3568d4;
  --white-color: #fff;
  --black-color: #000;
  --main-background-color: #28293d;
  /* for page background */
  // --panel-color: #383838;
  --panel-color: #2d2e45;
  /* for container background */
  --grey-text-color: #808080;
  --white-text-color: #fff;
  /* --side-bar-color: #454545; */
  --side-bar-color: #383838;
  --green-color: #77b900;
  /* --grey-border-color: #707070; */
  --grey-border-color: #5e5e5e;
  --border-radius: 4px;
  --grey-background-color: #b9b9b9;
  --table-row-bg-color-light: #2e2e2e;
  --table-row-bg-color-dark: #222222;
  --table-row-border-color: #363636;
  --table-font-color: #dedede;
  --input-border-color: #8f90a6;
  --btn-disabled-color: #555770;
  --dropdown-bg-color: #303047;
  --dropdown-select-color: #555770;
}

html {
  --antd-wave-shadow-color: var(--itrazo-red-color);
}

body {
  padding: 0;
  margin: 0;
  font-family: "Inter";
  background: var(--main-background-color);
  color: var(--white-text-color);
}

a {
  text-decoration: none;
}

a,
h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 0.075em;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0.5em 0;
}

input {
  color-scheme: dark;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s !important;
}

select {
  font-family: "Gotham Book";
}

option {
  font-family: "Gotham Book";
}

.btn {
  border-radius: 0.25em;
}

.gotham-medium-fourteen {
  font-family: "Gotham Medium";
  font-size: 14px;
}

.gotham-book-twelve {
  font-family: "Gotham Book";
  font-size: 12px;
}

.gotham-book-sixteen {
  font-family: "Gotham Book";
  font-size: 16px;
}

.marg-twenty-four {
  margin-right: 24px;
}

.sec_title,
.stepNumber {
  font-family: "Gotham Bold";
}

.fixed-height {
  min-height: 60vh;
  overflow-y: auto;
}

.paddingTopBottom {
  padding-top: 2%;
  padding-bottom: 2%;
}

.paddingLeftRight {
  padding: 2% 5% 2% 5% !important;
}

.right_side {
  margin-top: 105px;
  .stepBtn {
    display: flex;
    justify-content: end;
    border: 1px 0px 0px 0px;
    padding: 14px 24px;

    button {
      &.nextBtn {
        width: 97.61px;
        height: 36px;
        background: #3568d4;
        box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.12);
        border-radius: 6px;
        border-color: #3568d4;
        &:hover {
          color: #fff;
        }
      }

      &.backBtn {
        width: 97.61px;
        height: 36px;
        border: 1px solid #ffffff;
        box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.12);
        border-radius: 6px;
        background: none;
        color: rgb(193, 193, 193);
        &:hover {
          color: #fff;
        }
      }
    }
  }
}

.autocomplete-dropdown-container {
  position: absolute;
  left: 0;
  top: 100%;
  background: var(--dropdown-bg-color);
  z-index: 1;
  width: 100% !important;

  .inner {
    width: 100% !important;
    div {
      cursor: pointer;
      font-size: 12px;
      color: #fff;
      border-bottom: 1px solid var(--dropdown-select-color);
      padding: 5px;

      &:hover {
        color: var(--dropdown-select-color);
      }
    }
  }
}

.my-scroller {
  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #e5f0ff;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #6698fa;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #5865f2;
  }
}
