.right-col {
  padding: 0;
  border-radius: 0;
  background: transparent;
  border: 0px;
  margin: auto;

  .paddingLeft {
    padding: 0px 104px;
  }

  h4 {
    //styleName: Headings / h4;
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
  }

  .addProcduct span svg {
    margin-bottom: 5px !important;
  }

  .button-trans {
    border: 2px solid #3568d4;
    background: transparent;
    color: #fff;
    margin-top: 6px;
    height: 40px;
    width: 136px;
  }

  .border-top-custom {
    border-top: 0.2px solid #9dbff940;
  }

  .step-heading {
    margin-bottom: 31px;
  }
}
